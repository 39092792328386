export type BauhausSlug =
  | 'aircall2'
  | 'facebook5'
  | 'facebookads2'
  | 'ga4'
  | 'googleads'
  | 'googlecalendar3'
  | 'google_search_console'
  | 'hubspot4'
  | 'instagram4'
  | 'intercom4'
  | 'linkedinads'
  | 'linkedin3'
  | 'matomo'
  | 'pipedrive3'
  | 'shopify2'
  | 'spacecargobauhaus'
  | 'stripe2'
  | 'youtube5'
  | 'zendesk3'
  | 'zendeskagentstatus';

export const INTEGRATION_IMPORT_TIMESPANS = {
  shopify2: 90,
  zendesk3: 90,
} as const;

export const DATA_TYPES = {
  CURRENCY: 'CURRENCY',
  DECIMAL: 'DECIMAL',
  DURATION: 'DURATION',
  PERCENTAGE: 'PERCENTAGE',
} as const;

export const AGGREGATES = {
  MEAN: 'Average',
  MEDIAN: 'Median',
  MAX: 'Max',
  MIN: 'Min',
  SUM: 'Sum',
  COUNT: 'Total',
  COUNT_DISTINCT: 'Unique',
} as const;

export const COMPARISON_TYPES = {
  PREVIOUS_TIMESPAN: 'PREVIOUS_TIMESPAN',
  SAME_TIMESPAN_PREVIOUS_PERIOD: 'SAME_TIMESPAN_PREVIOUS_PERIOD',
} as const;

export const COMPARISON_DISPLAY_TYPES = {
  AMOUNT: 'AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
} as const;

export const DIMENSION_TYPES = {
  FIELD: 'FIELD',
  TIME: 'TIME',
} as const;

export const TIME_SUPPORT = {
  NONE: 'NONE',
  TIME_SPAN: 'TIME_SPAN',
  TIME_SERIES: 'TIME_SERIES',
  TIME_SERIES_ONLY: 'TIME_SERIES_ONLY',
} as const;

export const DIMENSION_SUPPORT = {
  MAX_ONE: 'MAX_ONE',
  MAX_ONE_PLUS_TIME: 'MAX_ONE_PLUS_TIME',
  OPTIONAL: 'OPTIONAL',
  REQUIRED_MAX_ONE: 'REQUIRED_MAX_ONE',
} as const;

export const TIME_UNITS = {
  YEAR: 'YEAR',
  QUARTER: 'QUARTER',
  MONTH: 'MONTH',
  WEEK: 'WEEK',
  DAY: 'DAY',
  HOUR: 'HOUR',
  MINUTE: 'MINUTE',
  SECOND: 'SECOND',
} as const;

export const TIME_DIRECTIONS = {
  PAST: 'PAST',
  FUTURE: 'FUTURE',
} as const;

export const FIELD_TYPES = {
  COLLECTION: 'COLLECTION',
  COUNTRY: 'COUNTRY',
  CURRENCY: 'CURRENCY',
  DATETIME: 'DATETIME',
  DURATION: 'DURATION',
  ENUM: 'ENUM',
  FLOAT: 'FLOAT',
} as const;

export const FILTER_TYPES = {
  COLLECTION: 'COLLECTION',
  TIMESPAN: 'TIMESPAN',
  NUMERIC: 'NUMERIC',
} as const;

export const FILTER_OPERATORS = {
  IS_IN: 'IS_IN',
  IS_NOT_IN: 'IS_NOT_IN',
  IS_NOT_NULL: 'IS_NOT_NULL',
  IS_NULL: 'IS_NULL',
  IS_WITHIN: 'IS_WITHIN',
  CONTAINS: 'CONTAINS',
  DOES_NOT_CONTAIN: 'DOES_NOT_CONTAIN',
  IS_BEFORE: 'IS_BEFORE',
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
} as const;

const TIME_SPAN_VISUALISATIONS = [
  'number',
  'geckometer',
  'column',
  'bar',
  'leaderboard',
  'table',
];
const TIME_SERIES_VISUALISATIONS = [
  'number',
  'geckometer',
  'line',
  'column',
  'bar',
  'leaderboard',
  'table',
];
const NO_TIME_VISUALISATIONS = [
  'number',
  'geckometer',
  'bar',
  'column',
  'leaderboard',
  'table',
];

export const TIME_SUPPORTS_FOR_TIME_DIMENSION = [
  TIME_SUPPORT.TIME_SERIES,
  TIME_SUPPORT.TIME_SERIES_ONLY,
];

export const VISUALISATIONS_FOR_TIME_WINDOW = ['number'];

export const UNSUPPORTED_VISUALISATIONS_WHEN_NO_DIMENSION = [
  'bar',
  'column',
  'leaderboard',
  'table',
];

export const UNSUPPORTED_VISUALISATIONS_WHEN_DIMENSION_REQUIRED = [
  'number',
  'geckometer',
];

export const VISUALISATIONS_BY_GOAL_SUPPORT = [
  'number',
  'line',
  'column',
  'bar',
];

export const SUPPORTED_VISUALISATIONS_FOR_TIME_DIMENSION = [
  'bar',
  'column',
  'line',
  'table',
];

export const VISUALISATIONS_BY_TIME_SUPPORT = {
  TIME_SPAN: TIME_SPAN_VISUALISATIONS,
  TIME_SERIES: TIME_SERIES_VISUALISATIONS,
  TIME_SERIES_ONLY: TIME_SERIES_VISUALISATIONS,
  NONE: NO_TIME_VISUALISATIONS,
};

// Comparison multi metric visualisations can only compare with metrics of the same data type has some sort of time support.
export const COMPARISON_MULTI_METRICS_VISUALISATIONS = ['line', 'column'];

export const MULTI_METRIC_VISUALISATIONS = [
  ...COMPARISON_MULTI_METRICS_VISUALISATIONS,
  'table',
];

export const MAX_METRICS_PER_VISUALISATIONS = {
  line: 9,
  column: 3,
  bar: 1,
  number: 1,
  leaderboard: 1,
  geckometer: 1,
  table: 9,
};
export const SUPPORTED_FILTER_FIELDS = [
  'BauhausFieldCollection',
  'BauhausFieldDatetime',
];

export const TIME_PLACEHOLDER_KEY = '_TIME';

export const COMPARISON_DIRECTIONS = {
  UP: 'UP',
  DOWN: 'DOWN',
};
export const COMPARISON_TYPE_ALLOWED_VISUALISATIONS = ['number'];
export const COMPARISON_DIRECTION_ALLOWED_VISUALISATIONS = ['number'];

export const CARDS = {
  COMPARISON: 'comparison',
  GOAL: 'goal',
  SPLIT_BY: 'splitBy',
  FILTER: 'filter',
  STATUS_INDICATORS: 'statusIndicators',
};

export const MAX_RAW_DATA_ROWS = 30;

export const METRIC_DIRECTION_TO_COMPARISON_DIRECTION_MAP = {
  HIGHER_IS_BETTER: COMPARISON_DIRECTIONS.UP,
  UNKNOWN: COMPARISON_DIRECTIONS.UP,
  LOWER_IS_BETTER: COMPARISON_DIRECTIONS.DOWN,
};
