import {
  chain,
  dropRightWhile,
  findIndex,
  forEachRight,
  isNaN,
  isNumber,
  isUndefined,
  reduce,
  reverse,
} from 'lodash';

import { getAbbreviation } from './get-abbreviation';
import { prettyDateAndTimeFormat } from './pretty-date-format';

const GROUPING_RE = /(\d)(?=(\d{3})+(?!\d))/g;

const FALLBACK_CURRENCIES = {
  USD: '$',
  GBP: '£',
  EUR: '€',
};

const MAX_PRECISION = 20;

const DURATION_UNIT = {
  years: 'y',
  days: 'd',
  hours: 'h',
  minutes: 'm',
  seconds: 's',
  milliseconds: 'ms',
};

let LOCALE_SUPPORT = false;
// Test for support of the locales arg in `toLocaleString`
// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#Example:_Checking_for_support_for_locales_and_options_arguments
try {
  (0).toLocaleString('i');
} catch (e) {
  LOCALE_SUPPORT = e.name === 'RangeError';
}

/*
 * Rules for decimal places
 * If abbreviation then up to 1 decimal place
 * If number < 10 then up to 3 decimal places
 * If number < 100 then up to 2 decimal places
 * If number < 1000 then up to 1 decimal place
 * If number >= 1000 then 0 decimal place
 */
function getPrecision(value, abbreviation) {
  if (!isUndefined(abbreviation) && abbreviation !== 'none') {
    return 1;
  }

  if (value < 10) {
    return 3;
  } else if (value < 100) {
    return 2;
  } else if (value < 1000) {
    return 1;
  }

  return 0;
}

function getSuffix(format, unit, prefix, suffix, currencySuffix) {
  const hasPrefixOrSuffix = !isUndefined(prefix) || !isUndefined(suffix);
  if (hasPrefixOrSuffix) {
    return suffix;
  }

  switch (format) {
    case 'decimal':
      return unit;
    case 'percent':
      return '%';
    case 'currency':
      return currencySuffix;
    default:
      return undefined;
  }
}

// Detect whether narrowSymbol is supported as a currencyDisplay format
// some older browsers support symbol but not narrowSymbol
const currencyDisplay = (() => {
  try {
    (0).toLocaleString(undefined, {
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    });
    return 'narrowSymbol';
  } catch (ex) {
    return 'symbol';
  }
})();

function localeFormatter(
  value,
  { autoPrecision, precision, abbreviation, format, unit, prefix, suffix },
) {
  const currency = format === 'currency' ? unit : undefined;
  const style = format === 'percent' ? 'decimal' : format;
  const negative = value < 0 ? '-' : undefined;
  const absoluteValue = Math.abs(value);

  // When a precision is given, use that as minimum and maximum for fraction digits
  // otherwise use 0 as minimum and the autoPrecision as maximum (strips away trailing zeros)
  let minimumFractionDigits = 0;

  let maximumFractionDigits = autoPrecision;

  if ('none' === precision) {
    maximumFractionDigits = MAX_PRECISION;
  } else if (!isUndefined(precision)) {
    minimumFractionDigits = precision;
    maximumFractionDigits = precision;
  }

  const numberValue = absoluteValue.toLocaleString(undefined, {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  const formattedValue = absoluteValue.toLocaleString(undefined, {
    style,
    currency,
    currencyDisplay,
    minimumFractionDigits,
    maximumFractionDigits,
  });

  const [currencyPrefix, currencySuffix] = formattedValue.split(numberValue);

  const hasPrefixOrSuffix = !isUndefined(prefix) || !isUndefined(suffix);
  let computedPrefix;
  if (hasPrefixOrSuffix) {
    computedPrefix = prefix;
  } else if (currencyPrefix && currencyPrefix !== '') {
    computedPrefix = currencyPrefix;
  }

  const computedSuffix = getSuffix(
    format,
    unit,
    prefix,
    suffix,
    currencySuffix,
  );

  return {
    negative,
    prefix: computedPrefix,
    abbreviation,
    suffix: computedSuffix,
    numberValue,
  };
}

// Safari and old IE
function fallbackFormatter(
  value,
  { autoPrecision, precision, abbreviation, format, unit, prefix, suffix },
) {
  let roundedValue;
  const negative = value < 0 ? '-' : undefined;
  const absoluteValue = Math.abs(value);

  // When a precision is given, we want to show trailing zeros,
  // that's why .toFixed is used.
  // When no precision is given fall back on the autoPrecision and
  // strip away trailing zeros.
  if ('none' === precision) {
    roundedValue = absoluteValue;
  } else if (!isUndefined(precision)) {
    roundedValue = absoluteValue.toFixed(precision);
  } else {
    // Lose precision
    const pow = Math.pow(10, autoPrecision);
    roundedValue = Math.round(absoluteValue * pow) / pow;
  }

  // Group with thousand separators
  const [intDigits, fractionDigits] = roundedValue.toString().split('.');

  // only format the hundreds with separators
  const integerDigits = intDigits.replace(GROUPING_RE, '$1,');

  // Support a small number of common currency symbols
  let currencyPrefix, currencySuffix;
  if (format === 'currency') {
    if (FALLBACK_CURRENCIES[unit]) {
      currencyPrefix = FALLBACK_CURRENCIES[unit];
    } else {
      currencySuffix = `${unit}`;
    }
  }

  const numberValue = fractionDigits
    ? `${integerDigits}.${fractionDigits}`
    : `${integerDigits}`;

  const hasPrefixOrSuffix = !isUndefined(prefix) || !isUndefined(suffix);
  const computedPrefix = hasPrefixOrSuffix ? prefix : currencyPrefix;
  const computedSuffix = getSuffix(
    format,
    unit,
    prefix,
    suffix,
    currencySuffix,
  );

  return {
    negative,
    prefix: computedPrefix,
    abbreviation,
    suffix: computedSuffix,
    numberValue,
  };
}

// Use toLocaleString when available
const formatter = LOCALE_SUPPORT ? localeFormatter : fallbackFormatter;

function getFormattedNumberSegments(
  val,
  { format = 'decimal', unit, referenceValue, numberFormat = {} } = {},
  vizType,
) {
  let value = val;

  // Percentages are expected as floats
  if (format === 'percent') {
    value *= 100;
  }

  const {
    abbreviation: fixedAbbreviation,
    precision,
    prefix,
    suffix,
  } = numberFormat;

  let reference = Math.abs(value);

  // When referenceValue is given use that value to get abbreviation and divider
  // so we can more easily compare disparate values
  // Don't apply comparison formatting to 0
  if (!isUndefined(referenceValue) && val !== 0) {
    reference = Math.abs(referenceValue);
  }

  const supportConsistentFormatting = ['bar', 'leaderboard', 'table'].includes(
    vizType,
  );

  const { abbreviation, divider } = getAbbreviation(
    reference,
    supportConsistentFormatting && fixedAbbreviation === undefined
      ? 'none'
      : fixedAbbreviation,
  );
  const autoPrecision = getPrecision(reference, abbreviation);

  const dividedValue = value / divider;

  return formatter(dividedValue, {
    autoPrecision,
    precision,
    abbreviation,
    format,
    unit,
    prefix,
    suffix,
  });
}

// Implement similar logic to HumanizeDuration.js
//
// We cannot use Moment duration as it does not allow us to drop
// years, months and weeks units.
export const SUPPORTED_UNITS = [
  'years',
  'days',
  'hours',
  'minutes',
  'seconds',
  'milliseconds',
];
const UNIT_MEASURES = {
  years: 31536000000,
  days: 86400000,
  hours: 3600000,
  minutes: 60000,
  seconds: 1000,
  milliseconds: 1,
};
const UNIT_THRESHOLDS = {
  years: 1,
  days: { t: 183, m: 365 },
  hours: { t: 12, m: 24 },
  minutes: { t: 30, m: 60 },
  seconds: { t: 30, m: 60 },
  milliseconds: { t: 500, m: 1000 },
};

const toMs = (rawValue, inputUnit = 'milliseconds') => {
  const val = Math.abs(parseFloat(rawValue));
  return val * UNIT_MEASURES[inputUnit];
};

const fromMs = (milliseconds, outputUnit = 'milliseconds') =>
  milliseconds / UNIT_MEASURES[outputUnit];

/**
 inputUnit - The unit rawValue is supplied in. A unit smaller than this will never be returned.
 largest - Represents the maximum number of units to return for the duration.
 maxUnit - If defined, will never return a bigger unit.
  Ie if the duration is `1 day, 2 hours` but maxUnit='hours', it will return `26 hours` instead.
 */
const getDurationSegments = (
  rawValue,
  inputUnit = 'milliseconds',
  largest = 2,
  maxUnit = SUPPORTED_UNITS[0],
) => {
  let ms = toMs(rawValue, inputUnit);

  // The given unit is the smallest supported unit so we don't want to show more
  // precision than that
  const SUPPORTED_UNITS_FOR_INPUT_UNIT = dropRightWhile(
    SUPPORTED_UNITS.slice(SUPPORTED_UNITS.indexOf(maxUnit)),
    (unit) => unit !== inputUnit,
  );

  return chain(SUPPORTED_UNITS_FOR_INPUT_UNIT)
    .reduce((segments, unitName) => {
      let unitCount;
      const unitMS = UNIT_MEASURES[unitName];

      if (unitName === inputUnit) {
        // ms is our smallest unit: no decimals
        unitCount = Math.round(ms / unitMS);
      } else {
        unitCount = Math.floor(ms / unitMS);
      }
      // Remove what we just figured out.
      ms -= unitCount * unitMS;
      return [...segments, [unitCount, unitName]];
    }, [])
    .thru((segments) => {
      // back propagate rounding based on requested maximum number of units
      // 1 - Get largest unit with non 0 value
      const largestUnit = findIndex(segments, ([val]) => val !== 0);
      const indexToTriggerRound = largestUnit + largest + 1;

      // No need to round if largest unit with value is milliseconds
      // OR
      // smallest unit to trigger round is smaller than milliseconds
      if (
        largestUnit === SUPPORTED_UNITS.length - 1 ||
        indexToTriggerRound > SUPPORTED_UNITS.length
      ) {
        return segments;
      }

      // 2 - take all segments used to compute round
      const segmentsToBubbleOn = segments.slice(0, indexToTriggerRound);

      // 3 - Bubble and round up if necessary
      let needRoundUp;
      const roundedSegments = [];
      forEachRight(segmentsToBubbleOn, ([val, unit], i) => {
        if (i + 1 === indexToTriggerRound) {
          // Possible value to round
          needRoundUp = val > UNIT_THRESHOLDS[unit].t;
          roundedSegments.push([0, unit]);
        } else {
          // Propagate previous rounded value if so
          let tmpVal = needRoundUp ? val + 1 : val;
          needRoundUp = tmpVal === UNIT_THRESHOLDS[unit].m; // round if max of current unit
          tmpVal = needRoundUp ? 0 : tmpVal;
          roundedSegments.push([tmpVal, unit]);
        }
      });

      return reverse(roundedSegments);
    })
    .dropWhile(([value]) => value === 0)
    .take(largest)
    .value();
};

const parseDurationInput = (input, outputUnit) => {
  const ms = reduce(
    input,
    (total, value, key) => {
      return total + UNIT_MEASURES[key] * value;
    },
    0,
  );

  return ms / UNIT_MEASURES[outputUnit];
};

const getInputFromDuration = (
  rawValue,
  inputUnit = 'milliseconds',
  largest = 2,
  smallestSegmentUnit = 'milliseconds',
) => {
  let value = rawValue;

  if (smallestSegmentUnit !== inputUnit) {
    const valueInMs = toMs(value, inputUnit);
    value = valueInMs / UNIT_MEASURES[smallestSegmentUnit];
  }

  const allUnits = chain(SUPPORTED_UNITS)
    .dropRightWhile((unit) => unit !== smallestSegmentUnit)
    .takeRight(largest)
    .value();

  const largestSegmentUnit = allUnits[0];

  const segments = getDurationSegments(
    value,
    smallestSegmentUnit,
    largest,
    largestSegmentUnit,
  );
  const allUnitsArray = allUnits.map((u) => [0, u]);

  return reduce(
    [...allUnitsArray, ...segments],
    (total, [v, unit]) => {
      total[unit] = `${v}`;
      return total;
    },
    {},
  );
};

const humanizeDuration = (val, unit) => {
  const segments = getDurationSegments(val, unit);

  if (!segments.length) {
    // Render 0 as 0 with provided unit
    return `0${DURATION_UNIT[unit]}`;
  }

  return segments.map(([v, u]) => `${v}${DURATION_UNIT[u]}`).join(' ');
};

function humaniseNumber(val, options = {}, vizType) {
  if (!isNumber(val)) {
    return val;
  }

  if (options.format === 'duration') {
    return humanizeDuration(val, options.unit);
  }

  const {
    negative = '',
    prefix = '',
    abbreviation = '',
    suffix = '',
    numberValue,
  } = getFormattedNumberSegments(val, options, vizType);

  const separator = abbreviation && suffix ? ' ' : '';

  return `${negative}${prefix}${numberValue}${abbreviation}${separator}${suffix}`;
}

function humanizeDate(val, timezone) {
  if (isNaN(Date.parse(val))) {
    return val;
  }

  return prettyDateAndTimeFormat(val, timezone);
}

function humanize(value, options = {}, timezone = 'UTC') {
  if (options.format === 'datetime') {
    return humanizeDate(value, timezone);
  }

  return humaniseNumber(value, options);
}

export {
  fromMs,
  getDurationSegments,
  getFormattedNumberSegments,
  getInputFromDuration,
  getPrecision,
  getSuffix,
  humaniseNumber,
  humanize,
  humanizeDuration,
  parseDurationInput,
  toMs,
  UNIT_MEASURES,
};
