import { evictCachedAllowanceData } from '@Hooks/use-dashboard-allowance';
import { trackEvent } from '@Tracking';
import { userDeleted } from '@Tracking/events';

import { formatMessage } from '../../lib/i18n';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import apolloClient from '../../services/concierge-service/apollo-client';
import { deleteUser as deleteUserService } from '../../services/permission-service';
import { actions as toastActions } from '../../toast/reducer/toast-slice';

export const usersFetchStart = createAction('People:USERS_FETCH_START');
export const usersFetchSuccessful = createAction(
  'People:USERS_FETCH_SUCCESSFUL',
);
export const usersFetchFailed = createErrorAction('People:USERS_FETCH_FAILED');
export const deleteUserStart = createAction('People:DELETE_USER_START');
export const deleteUserSuccessful = createAction(
  'People:DELETE_USER_SUCCESSFUL',
);
export const deleteUserFailed = createAction('People:DELETE_USER_FAILED');

export const deleteUser = (groupId, userId) => async (dispatch) => {
  try {
    dispatch(deleteUserStart({ userId }));
    // groupId is required until we have a new dedicated end point.
    await deleteUserService(groupId, userId);

    evictCachedAllowanceData(apolloClient.create().cache);

    dispatch(deleteUserSuccessful({ userId }));
    dispatch(
      toastActions.showSuccessToast(
        formatMessage('people.deleteUserSuccessful'),
      ),
    );

    trackEvent(userDeleted());
  } catch (e) {
    dispatch(deleteUserFailed({ userId }));
    dispatch(toastActions.showGenericErrorToast());
  }
};
