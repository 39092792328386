import { trim } from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericTableTransform } from '../per-visualisation/table';
import { getDisplayValue } from '../shared/helpers';

import {
  getColumnFormat,
  getCountryName,
  getDatapointValue,
  getDataResponse,
  getImageType,
  getImageValue,
  UNIT_DURATIONS,
} from './helpers';
import { warningsTransform } from './warning';

const SUPPORTED_FORMATS = [
  'decimal',
  'currency',
  'percent',
  'datetime',
  'duration',
  'time_window',
];

const getTableDisplayValue = (
  isRawData,
  value,
  column,
  originalType,
  index,
) => {
  if (originalType === 'BauhausDataColumnTimeWindow') {
    return { value };
  }

  if (originalType === 'BauhausDataColumnRecord') {
    return {
      value: getDisplayValue(value),
      image: getImageValue(value),
    };
  }

  if (!isRawData && index === 0 && trim(value) === '') {
    return 'No value';
  }

  if (originalType === 'BauhausDataColumnCountry') {
    return getCountryName(value);
  }

  if (originalType === 'BauhausDataColumnDuration') {
    return getDisplayValue(value / UNIT_DURATIONS[column.unit]);
  }

  return value;
};

const tableTransform = (result, config, ___, data) => {
  if (!data) {
    return {};
  }

  const isRawData = result.data.bauhausRawDataRequest !== undefined;

  const columns = data.columns.map((column) => {
    const format = getColumnFormat([column]);

    let _col = {
      heading: column.displayName,
      id: column.id,
      imageType: getImageType(column, config),
    };

    if (format.format === 'datetime') {
      const alwaysShowTime = column.showTime === 'ALWAYS';
      _col.useRelativeTime = isRawData && !alwaysShowTime;
      _col.alwaysShowTime = alwaysShowTime;
    }

    if (SUPPORTED_FORMATS.includes(format.format)) {
      _col = { ..._col, ...format };
    }

    return _col;
  });

  const rows = data.rows.map((row) =>
    row.values.map((value, i) =>
      getTableDisplayValue(
        /*
         * Raw data has different visualization constraints than
         * tables or leaderboards, even though the data is
         * displayed in similar ways.
         */
        isRawData,
        getDatapointValue(value),
        columns[i],
        /*
         * we pass down the original typename as a country name is actually
         * a string in the end so we don't want to confuse the table widget into
         * believing country is anything more than a string
         */
        data.columns[i].__typename,
        i,
      ),
    ),
  );

  return {
    columns,
    data: rows,
  };
};

/*
 * transform
 *
 * This is the full transformer that takes the high
 * fidelity raw data from our backend services and
 * transforms it into a low fidelity data payload
 * that our visualisations expect.
 *
 * rawData + widgetConfig => transform => transformedData
 */
const transform = composeTransformers(
  getDataResponse,
  tableTransform,
  genericTableTransform,
  warningsTransform,
);

/*
 * applyConfig
 *
 * This is a lightweight transformer that doesn't require
 * the high fidelity raw data. This can be used in cases
 * where an update to config can affect the widget data
 * in a way that is completely disconnected from the
 * raw data. For example applying a fixed goal.
 *
 * transformedData1 + widgetConfig => transform => transformedData2
 */
const applyConfig = (data) => data;

export { applyConfig, transform };
