import { graphql } from '@Generated/gql';
import { Screen, ScreenContentInput } from '@Generated/graphql';
import { evictCachedAllowanceData } from '@Hooks/use-dashboard-allowance';

import apolloClient from './apollo-client';

export const listScreensQuery = graphql(`
  query ListScreens {
    screens {
      id
      name
      content {
        type
        id
        legacyId
      }
    }
  }
`);

export const broadcastContentToScreenMutation = graphql(`
  mutation BroadcastContentToScreen(
    $screenId: ID!
    $content: ScreenContentInput!
  ) {
    screenBroadcast(screenId: $screenId, content: $content) {
      screen {
        id
        name
        content {
          type
          id
          legacyId
        }
      }
    }
  }
`);

export const deleteScreenMutation = graphql(`
  mutation DeleteScreen($screenId: ID!) {
    screenDelete(screenId: $screenId) {
      deletedScreenId
    }
  }
`);

export const pairScreenMutation = graphql(`
  mutation PairScreen($pairingCode: String!, $content: ScreenContentInput!) {
    screenPair(pairingCode: $pairingCode, content: $content) {
      screen {
        id
        name
        content {
          type
          id
          legacyId
        }
      }
    }
  }
`);

export const renameScreenMutation = graphql(`
  mutation RenameScreen($screenId: ID!, $name: String!) {
    screenRename(screenId: $screenId, name: $name) {
      screen {
        id
        name
        content {
          type
          id
          legacyId
        }
      }
    }
  }
`);

export const refreshRemoteDashboardMutation = graphql(`
  mutation RefreshRemoteDashboard($dashboardId: ID!) {
    dashboardRemoteRefresh(dashboardId: $dashboardId) {
      dashboardId
    }
  }
`);

export const refreshRemoteSharingLoopMutation = graphql(`
  mutation RefreshRemoteSharingLoop($loopId: ID!) {
    loopRemoteRefresh(loopId: $loopId) {
      loopId
    }
  }
`);

export const listScreens = async (): Promise<
  Array<Omit<Screen, 'createdAt'>>
> => {
  const client = apolloClient.create();

  const { data } = await client.query({
    query: listScreensQuery,
  });

  return data.screens;
};

export const broadcastContentToScreen = async (
  screenId: string,
  content: ScreenContentInput,
): Promise<Omit<Screen, 'createdAt'>> => {
  const client = apolloClient.create();

  const { data } = await client.mutate({
    mutation: broadcastContentToScreenMutation,
    variables: {
      screenId,
      content,
    },
    refetchQueries: [{ query: listScreensQuery }],
  });

  return data!.screenBroadcast!.screen;
};

export const deleteScreen = async (screenId: string): Promise<void> => {
  const client = apolloClient.create();

  await client.mutate({
    mutation: deleteScreenMutation,
    variables: {
      screenId,
    },
    update: (cache) => {
      evictCachedAllowanceData(cache);
    },
    refetchQueries: [{ query: listScreensQuery }],
  });
};

export const pairScreen = async (
  pairingCode: string,
  content: ScreenContentInput,
): Promise<Omit<Screen, 'createdAt'>> => {
  const client = apolloClient.create();

  const { data } = await client.mutate({
    mutation: pairScreenMutation,
    variables: {
      pairingCode,
      content,
    },
    update: (cache) => {
      evictCachedAllowanceData(cache);
    },
    refetchQueries: [{ query: listScreensQuery }],
  });

  return data!.screenPair!.screen;
};

export const renameScreen = async (
  screenId: string,
  name: string,
): Promise<Omit<Screen, 'createdAt'>> => {
  const client = apolloClient.create();

  const { data } = await client.mutate({
    mutation: renameScreenMutation,
    variables: {
      screenId,
      name,
    },
    refetchQueries: [{ query: listScreensQuery }],
  });

  return data!.screenRename!.screen;
};

export const refreshDashboard = async (dashboardId: string) => {
  const client = apolloClient.create();

  const { data } = await client.mutate({
    mutation: refreshRemoteDashboardMutation,
    variables: {
      dashboardId,
    },
  });

  return data!.dashboardRemoteRefresh!.dashboardId;
};

export const refreshSharingLoop = async (loopId: string) => {
  const client = apolloClient.create();

  const { data } = await client.mutate({
    mutation: refreshRemoteSharingLoopMutation,
    variables: {
      loopId,
    },
  });

  return data!.loopRemoteRefresh!.loopId;
};
