import localForage from 'localforage';
import { get, isEmpty, isUndefined, some, values } from 'lodash';

import { conform, trackEvent } from '@Tracking';
import {
  widgetCopied,
  widgetDeleted,
  widgetMovedOffCanvas,
  widgetMovedToCanvas,
} from '@Tracking/events';

import {
  convertWidgetLayoutFromGrid,
  convertWidgetLayoutToGrid,
} from '../../dashboard/box-layout/helpers';
import { dispatchToGeckoJS } from '../../lib/gecko-view';
import { redirect } from '../../lib/global';
import { formatMessage } from '../../lib/i18n';
import { getEditWidgetPath } from '../../lib/path';
import createAction from '../../lib/redux/create-action';
import { isBauhaus, isUniversal } from '../../lib/service';
import waitForState from '../../lib/wait-for-state';
import * as widgetService from '../../lib/widget-service';
import * as managementService from '../../services/management-service';
import {
  actions as toastActions,
  ToastTypes,
} from '../../toast/reducer/toast-slice';
import importUniversalCompactConfig from '../../universal-config/components/universal-compact-config/import-universal-compact-config';
import { DEFAULT_SIZE } from '../../visualisation/helpers';
import { dataReceived } from '../../widget-data/actions/widget-data-actions';
import {
  closeMenu,
  openEditWidget,
  openMenu,
} from '../../widget-menu/actions/widget-menu-actions';
import { WIDGET_MENU_VIEWS } from '../../widget-menu/constants';
import {
  DASHBOARD_WIDGET_LOCATION,
  LOCATION_CHANGE_CLICK,
  LOCATION_CHANGE_DRAG,
  OFF_CANVAS_WIDGET_LOCATION,
  STORAGE_KEY_LAST_ADDED_WIDGET,
} from '../constants/widget-location-constants';

const fullOpenMenu =
  ({ widgetKey, view }) =>
  (dispatch) => {
    dispatch(openMenu({ widgetKey, view }));
    dispatchToGeckoJS('widget-menu', { open: true, widgetKey });
  };

export const setWidgets = createAction('Widgets:SET_WIDGETS');
export const widgetAdded = createAction('Widgets:WIDGET_ADDED');
export const widgetChanged = createAction('Widgets:WIDGET_CHANGED');
export const widgetConfigChanged = createAction('Widgets:CONFIG_CHANGED');
export const widgetRemoved = createAction('Widgets:WIDGET_REMOVED');
export const widgetDragStart = createAction('Widgets:DRAG_START');
export const widgetDragEnd = createAction('Widgets:DRAG_END');
export const widgetLocationChanged = createAction('Widgets:LOCATION_CHANGED');
export const anticipateWidgetLocationChange = createAction(
  'Widgets:ANTICIPATE_WIDGET_LOCATION_CHANGE',
);
export const locationChangeStart = createAction(
  'Widgets:LOCATION_CHANGE_START',
);
export const locationChangeAccomplished = createAction(
  'Widgets:LOCATION_CHANGE_ACCOMPLISHED',
);
export const locationChangeEnd = createAction('Widgets:LOCATION_CHANGE_END');
export const setAwaitingInitialServerSideRefresh = createAction(
  'Widgets:SET_AWAITING_INITIAL_SERVER_SIDE_REFRESH',
);

export const addWidget =
  ({ widget, widgetType }) =>
  (dispatch, getState) => {
    const {
      dashboard: { dashboard },
    } = getState();

    const transformedWidget = {
      ...widget,
      widget: {
        ...widget.widget,
        layout: convertWidgetLayoutToGrid(widget.widget.layout, dashboard),
      },
    };

    dispatch(widgetAdded({ widget: transformedWidget, widgetType }));
  };

export const reset =
  ({ widgets: widgetsPayload, widgetTypes }) =>
  async (dispatch, getState) => {
    await waitForState(getState, (state) => !!state.dashboard.dashboard);

    const {
      dashboard: { dashboard },
    } = getState();
    const adjustedWidgets = widgetsPayload.map((widget) => {
      return {
        ...widget,
        widget: {
          ...widget.widget,
          layout: convertWidgetLayoutToGrid(widget.widget.layout, dashboard),
        },
      };
    });

    dispatch(setWidgets({ widgets: adjustedWidgets, widgetTypes }));
    const lastWidgetKey = await localForage.getItem(
      STORAGE_KEY_LAST_ADDED_WIDGET,
    );
    if (lastWidgetKey) {
      // We need to ensure that widget / widgetData state is loaded before
      // we can dispatch the action to move the widget into the `editWidget` state
      await waitForState(
        getState,
        (state) =>
          !!state.widgets[lastWidgetKey] && !!state.widgetData[lastWidgetKey],
      );

      const { widgets, widgetData, imports } = getState();

      localForage.removeItem(STORAGE_KEY_LAST_ADDED_WIDGET);

      const widgetToOpen = widgets[lastWidgetKey];

      // Are there any active imports that match the `serviceAccount` that this widget is associated with,
      // and are those imports currently pending? If so, let's not open the compact config and just return here
      if (
        some(
          get(imports, 'activeImports'),
          (imp) =>
            imp.serviceAccountId ===
              widgetToOpen.service_account_id.toString() &&
            imp.status.__typename === 'ImportStatusPending',
        )
      ) {
        return;
      }

      dispatch(
        fullOpenMenu({
          widgetKey: lastWidgetKey,
          view: WIDGET_MENU_VIEWS.EDIT,
        }),
      );
      dispatch(
        openEditWidget({
          widget: widgets[lastWidgetKey],
          data: widgetData[lastWidgetKey],
        }),
      );
    }

    /*
     * Preload the universal config bundle if the
     * dashboard contains any universal widgets.
     */
    if (dashboard.can_edit) {
      const formattedWidgets = getState().widgets;
      const hasUniversalWidgets = some(formattedWidgets, (widget) =>
        isUniversal(widget.serviceName),
      );

      if (hasUniversalWidgets) {
        importUniversalCompactConfig();
      }
    }
  };

export const layoutChanged =
  (widgetKey, layout) => async (dispatch, getState) => {
    const {
      widgets,
      dashboard: { dashboard },
    } = getState();
    const { layout: originalLayout, location: originalLocation } =
      widgets[widgetKey];
    const transformedLayout = convertWidgetLayoutFromGrid(layout, dashboard);

    dispatch(
      widgetLocationChanged({
        widgetKey,
        location: DASHBOARD_WIDGET_LOCATION,
        layout,
      }),
    );

    try {
      // BE stores layout data at root of JSON
      await widgetService.updateLocation(widgetKey, {
        location: DASHBOARD_WIDGET_LOCATION,
        layout: transformedLayout,
      });
    } catch (error) {
      dispatch(
        widgetLocationChanged({
          widgetKey,
          location: originalLocation,
          layout: originalLayout,
        }),
      );
      dispatch(toastActions.showGenericErrorToast());
    }
  };

// Currently there are three things that determine that a widget uses compact config:
// 1. It's a Universal widget
// 2. It's a Bauahus widget (we also check that it has a Bauhaus config, as Bauhaus widgets
//    may share a `serviceName` with Heirloom)
// 3. It's a Database widget
export const usesCompactConfig = (serviceName, config = {}) =>
  isUniversal(serviceName) ||
  (isBauhaus(serviceName) && !!config.bauhaus) ||
  serviceName === 'database';

const getPlatformName = (config = {}) => {
  if (!!config.juno) {
    return 'INTEGRATION_DATASETS';
  }

  return undefined;
};

export const editWidget =
  (widgetKey, shouldOpenSIPanel) => (dispatch, getState) => {
    const {
      dashboard,
      widgets,
      widgetData,
      user: { organization },
    } = getState();
    const { id: dashboardId } = dashboard.dashboard;
    const widget = widgets[widgetKey];
    const data = widgetData[widgetKey];
    const { serviceName } = widget || {};
    const platform = getPlatformName(widget.config);

    if (usesCompactConfig(serviceName, widget.config)) {
      // Make sure menu is open as we might click on edit widget outside of the menu
      dispatch(fullOpenMenu({ widgetKey }));
      dispatch(openEditWidget({ widget, data }));
    } else {
      dispatch(closeMenu());
      const editPath = getEditWidgetPath({
        widgetKey,
        dashboardId,
        serviceName,
        organization,
        platform,
        shouldOpenSIPanel,
      });
      redirect(editPath);
    }
  };

export const deleteWidget = (widgetKey) => async (dispatch, getState) => {
  try {
    const {
      dashboard: {
        dashboard: { id: dashboardId },
      },
    } = getState();
    const widget = getState().widgets[widgetKey] || {};

    dispatch(widgetRemoved(widgetKey));
    await managementService.deleteWidget(dashboardId, widgetKey);

    // First we unmount all the widgets from gecko-js, and then we can notify
    // gecko-js to remove the widget from the collection. Without a setTimeout
    // there is an invariant exception somewhere in react
    setTimeout(() => {
      dispatchToGeckoJS('widget:deleted', widgetKey); // Notify gecko-js
    }, 0);

    trackEvent(
      widgetDeleted({
        'Legacy widget ID': `${widget.id}`,
        Visualisation: widget.visualisation.vizType,
        'Widget type ID': `${widget.widgetType.id}`,
        'Widget type name': widget.widgetType.title,
        ...conform.integration(widget.widgetType.integration),
      }),
    );
  } catch (e) {
    dispatch(toastActions.showGenericErrorToast());
  }
};

export const widgetUpdateConfig =
  ({ key, attributes }) =>
  async (dispatch, getState) => {
    const {
      widgets: {
        [key]: { config: originalConfig },
      },
    } = getState();

    dispatch(
      widgetConfigChanged({
        key,
        config: { ...originalConfig, ...attributes },
      }),
    );

    try {
      await widgetService.patchConfig(key, attributes);
    } catch (error) {
      dispatch(
        widgetConfigChanged({
          key,
          config: originalConfig,
        }),
      );
      dispatch(toastActions.showGenericErrorToast());
    }
  };

export const copyWidget =
  ({ widgetKey, dashboard }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const currentDashboard = state.dashboard.dashboard;
      const originalWidget = state.widgets[widgetKey];

      const widget = await widgetService.copyToDashboard(
        widgetKey,
        currentDashboard.id,
        dashboard.id,
      );
      dispatchToGeckoJS('widget:copied', {
        widget,
        dashboardId: dashboard.id,
      });

      const { configuration: { title } = {} } = widget;
      const widgetTitle = !isEmpty(title)
        ? title
        : formatMessage('widget.copy.successToast.defaultWidgetTitle');
      let toast;

      if (dashboard.id !== currentDashboard.id) {
        toast = toastActions.showToast({
          type: ToastTypes.WIDGET_COPIED,
          widgetTitle,
          dashboardTitle: dashboard.title,
          dashboardPath: `/edit/dashboards/${dashboard.id}`,
        });
      } else {
        const widgetHasMovedOffCanvas =
          widget.location === OFF_CANVAS_WIDGET_LOCATION;
        const message = widgetHasMovedOffCanvas
          ? 'widget.copy.successToast.offCanvas'
          : 'widget.copy.successToast.currentDashboard';

        if (widgetHasMovedOffCanvas) {
          trackEvent(
            widgetMovedOffCanvas({
              'Legacy widget ID': `${widget.id}`,
              Visualisation: originalWidget.visualisation.vizType,
              'Widget type ID': `${originalWidget.widgetType.id}`,
              'Widget type name': originalWidget.widgetType.title,
              ...conform.integration(originalWidget.widgetType.integration),
            }),
          );
        }

        toast = toastActions.showSuccessToast(
          formatMessage(message, {
            widgetTitle,
          }),
        );
      }

      dispatch(closeMenu());
      dispatch(toast);

      trackEvent(
        widgetCopied({
          'Legacy widget ID': `${originalWidget.id}`,
          Visualisation: originalWidget.visualisation.vizType,
          'Widget type ID': `${originalWidget.widgetType.id}`,
          'Widget type name': originalWidget.widgetType.title,
          ...conform.integration(originalWidget.widgetType.integration),
        }),
      );
    } catch (e) {
      dispatch(toastActions.showGenericErrorToast());
    }
  };

const _getNextWidgetOrder = (widgets, widget = {}) => {
  const { order = 0 } = widget.layout || {};

  const widgetList = values(widgets).filter((w) => !isUndefined(w.layout));
  const maxOrder = Math.max(...widgetList.map((w) => w.layout.order));

  // If the widget is not already at the top
  if (order < maxOrder) {
    return maxOrder + 1;
  }

  // If there is more than one widget with the same zIndex
  if (widgetList.filter((w) => w.layout.order === maxOrder).length > 1) {
    return maxOrder + 1;
  }

  return order;
};

const _getNextStableWidgetOrder = (widgets) => {
  const stableWidgets = values(widgets).filter(
    (widget) => widget.location === OFF_CANVAS_WIDGET_LOCATION,
  );
  const maxOrder = Math.max(0, ...stableWidgets.map((w) => w.stableOrder || 0));

  return maxOrder + 1;
};

export const resizeWidget =
  ({ widgetKey, size }) =>
  (dispatch, getState) => {
    const { widgets } = getState();
    const widget = widgets[widgetKey];

    const layout = {
      left: widget.layout.left,
      top: widget.layout.top,
      order: _getNextWidgetOrder(widgets, widget),
      pixel_width: size.width,
      pixel_height: size.height,
    };

    dispatch(layoutChanged(widgetKey, layout));
    dispatchToGeckoJS('widget:resized', {
      widgetKey,
      layout,
    });
  };

export const moveWidget =
  ({ widgetKey, position }) =>
  (dispatch, getState) => {
    const {
      widgets,
      dashboard: { dashboard },
    } = getState();
    const widget = widgets[widgetKey];
    const order = _getNextWidgetOrder(widgets, widget);

    if (
      widget.layout.left !== position.left ||
      widget.layout.top !== position.top ||
      widget.layout.order !== order ||
      widget.location === OFF_CANVAS_WIDGET_LOCATION
    ) {
      // If we were off-canvas, and we're moving onto the dashboard, track it
      if (widget.location === OFF_CANVAS_WIDGET_LOCATION) {
        const [defaultWidth, defaultHeight] = DEFAULT_SIZE;
        const convertedLayout = convertWidgetLayoutToGrid(
          {
            ...position,
            pixel_width: defaultWidth,
            pixel_height: defaultHeight,
          },
          dashboard,
        );
        dispatch(
          layoutChanged(widgetKey, {
            ...widget.layout,
            ...convertedLayout,
            left: position.left,
            top: position.top,
            order,
          }),
        );

        trackEvent(
          widgetMovedToCanvas({
            'Legacy widget ID': `${widget.id}`,
            Visualisation: `${widget.visualisation.vizType}`,
            'Widget type ID': `${widget.widgetType.id}`,
            'Widget type name': `${widget.widgetType.title}`,
            ...conform.integration(widget.widgetType.integration),
          }),
        );
      } else {
        dispatch(
          layoutChanged(widgetKey, {
            ...widget.layout,
            left: position.left,
            top: position.top,
            order,
          }),
        );
      }
    }
  };

export const moveOffCanvas =
  (widgetKey, userAction) => async (dispatch, getState) => {
    const {
      widgets,
      dashboard: { dashboard },
    } = getState();
    const {
      layout: originalLayout,
      id: widgetId,
      widgetType,
      visualisation: { vizType: visualisationType },
    } = widgets[widgetKey];
    const isUserAction = [LOCATION_CHANGE_CLICK, LOCATION_CHANGE_DRAG].includes(
      userAction,
    );

    const trackMove = () => {
      trackEvent(
        widgetMovedOffCanvas({
          'Legacy widget ID': `${widgetId}`,
          Visualisation: visualisationType,
          'Widget type ID': `${widgetType.id}`,
          'Widget type name': `${widgetType.title}`,
          ...conform.integration(widgetType.integration),
        }),
      );
    };

    try {
      const [pixelWidth, pixelHeight] = DEFAULT_SIZE;
      const stableOrder = _getNextStableWidgetOrder(widgets);
      const layout = {
        top: 0,
        left: 0,
        order: 1,
        pixel_width: pixelWidth,
        pixel_height: pixelHeight,
      };
      const transformedLayout = convertWidgetLayoutToGrid(layout, dashboard);

      const locationChangeMove = () =>
        widgetLocationChanged({
          widgetKey,
          location: OFF_CANVAS_WIDGET_LOCATION,
          layout: transformedLayout,
          stableOrder,
        });

      if (isUserAction && userAction === LOCATION_CHANGE_CLICK) {
        dispatch(locationChangeStart(widgetKey));

        setTimeout(() => {
          dispatch(locationChangeMove());
          dispatch(locationChangeAccomplished(widgetKey));

          setTimeout(() => {
            dispatch(locationChangeEnd(widgetKey));
          }, 350);
        }, 580);
      } else {
        dispatch(locationChangeMove());
      }

      await widgetService.updateLocation(widgetKey, {
        location: OFF_CANVAS_WIDGET_LOCATION,
      });

      trackMove();
    } catch (e) {
      dispatch(
        widgetLocationChanged({
          widgetKey,
          location: DASHBOARD_WIDGET_LOCATION,
          layout: originalLayout,
        }),
      );
      dispatch(toastActions.showGenericErrorToast());
    }
  };

export const processDataReceived = (payload) => (dispatch) => {
  dispatch(dataReceived(payload));
};
