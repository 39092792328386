import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { formatMessage } from '../../lib/i18n';
import * as toastStorage from '../toast-storage';

export const ToastTypes = {
  SUCCESS: 'Toast:SUCCESS',
  ERROR: 'Toast:ERROR',
  ADDED_SCREEN: 'Toast:ADDED_SCREEN',
  FOOTER_SWITCHED_ON: 'Toast:FOOTER_SWITCHED_ON',
  DEMOTED_TO_DASHBOARD_VIEW_ONLY: 'Toast:DEMOTED_TO_DASHBOARD_VIEW_ONLY',
  SHARING_LINK_COPIED: 'Toast:SHARING_LINK_COPIED',
  REGENERATED_SHARING_LINK: 'Toast::REGENERATED_SHARING_LINK',
  REGENERATED_LOOP_SHARING_LINK: 'Toast::REGENERATED_LOOP_SHARING_LINK',
  CREATED_LOOP: 'Toast::CREATED_LOOP',
  ADD_A_GOAL: 'Toast::ADD_A_GOAL',
  DASHBOARD_TEMPLATE_CREATE_ERROR: 'Toast::DASHBOARD_TEMPLATE_CREATE_ERROR',
  WIDGET_COPIED: 'Toast::WIDGET_COPIED',
} as const;

export type ToastType = keyof typeof ToastTypes;
export type ToastValue = ValueOf<typeof ToastTypes>;

export type Toast =
  | {
      type: ToastValue;
      message: string;
    }
  | {
      type:
        | typeof ToastTypes.SHARING_LINK_COPIED
        | typeof ToastTypes.FOOTER_SWITCHED_ON;
    }
  | {
      type: typeof ToastTypes.DEMOTED_TO_DASHBOARD_VIEW_ONLY;
      userId: string;
    }
  | {
      type: typeof ToastTypes.REGENERATED_SHARING_LINK;
      link: string;
    }
  | {
      type:
        | typeof ToastTypes.REGENERATED_LOOP_SHARING_LINK
        | typeof ToastTypes.CREATED_LOOP;
      link: string;
      loopId: string;
    }
  | {
      type: typeof ToastTypes.WIDGET_COPIED;
      widgetTitle: string;
      dashboardTitle: string;
      dashboardPath: string;
    };

export type ToastState = {
  toast?: Toast;
};

export const initialState: ToastState = {};

export const slice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    /** Hides any visible Toast */
    hideToast: (state: ToastState) => {
      delete state.toast;
    },

    /** Shows a successfull Toast */
    showSuccessToast: (state: ToastState, action: PayloadAction<string>) => {
      state.toast = {
        type: ToastTypes.SUCCESS,
        message: action.payload,
      };
    },

    /** Shows an error Toast */
    showErrorToast: (state: ToastState, action: PayloadAction<string>) => {
      state.toast = {
        type: ToastTypes.ERROR,
        message: action.payload,
      };
    },

    showGenericErrorToast: (state: ToastState) => {
      state.toast = {
        type: ToastTypes.ERROR,
        message: formatMessage('generic.request.failure'),
      };
    },

    showToast: (state: ToastState, action: PayloadAction<Toast>) => {
      state.toast = action.payload;
    },

    /** Shows a toast that's been scheuled to load after a page redirect */
    showScheduledToast: (state: ToastState) => {
      const toast = toastStorage.loadToastFromStorage();
      if (!toast) {
        return;
      }

      state.toast = toast;
    },

    /** Schedules a toast to be displayed after a redirect */
    scheduleToast: (state: ToastState, action: PayloadAction<Toast>) => {
      toastStorage.scheduleToast(action.payload);
    },
  },
});

export const actions = slice.actions;
export default slice.reducer;
