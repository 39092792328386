import {
  SESSION_TYPES,
  trackDashboardViewed,
  VIEW_TYPES,
} from '@Tracking/dashboard-viewed';
import { getRandomTrackingId } from '@Tracking/session-tracker/tracker-helpers';

import { GRAPHQL_LAYOUT_TYPES_TRACKING } from '../../dashboard/dashboard-layout/constants';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import * as managementService from '../../services/management-service';
import SessionTracker from '../../tracking/session-tracker';
import { fetchJSONWithCredentials } from '../../universal/fetch';

export const setUser = createAction('User:SET_USER');
export const setUserGroups = createAction('User:SET_USER_GROUPS');
export const setUserOrganization = createAction('User:SET_USER_ORGANIZATION');

export const getCurrentUserSuccess = createAction(
  'User:GET_CURRENT_USER_SUCCESS',
);
export const getCurrentUserFailed = createErrorAction(
  'User:GET_CURRENT_USER_FAILED',
);

export const getUserOrganizationSuccess = createAction(
  'User:GET_USER_ORGANIZATION_SUCCESS',
);
export const getUserOrganizationFailed = createErrorAction(
  'User:GET_USER_ORGANIZATION_FAILED',
);

export const getDashboardsStart = createAction('User:GET_DASHBOARDS_START');
export const getDashboardsSuccess = createAction('User:GET_DASHBOARDS_SUCCESS');
export const getDashboardsFailed = createErrorAction(
  'User:GET_DASHBOARDS_FAILED',
);

export const initialiseNonGraphQLPage = () => async (dispatch) => {
  try {
    const user = await managementService.getUser();
    const organization = await managementService.getOrganization(
      user.organization_id,
    );

    dispatch(getUserOrganizationSuccess(organization));
    dispatch(getCurrentUserSuccess(user));
  } catch (e) {
    dispatch(getCurrentUserFailed(e));
  }
};

export const initialiseSharedGraphQLDashboard =
  (sharingHash, layoutType, dashboardId, legacyDashboardId) =>
  async (dispatch) => {
    const data = await fetchJSONWithCredentials(
      `/v5/dashboards/${sharingHash}/bootstrap.json`,
    );

    const { currentOrganization: organization, currentUser: user } = data;

    dispatch(getCurrentUserSuccess(user));
    dispatch(setUserOrganization(organization));

    trackDashboardViewed({
      legacyUserId: user.id,
      viewType: VIEW_TYPES.DESKTOP,
      sessionType: SESSION_TYPES.SHARING_URL,
      dashboardType: GRAPHQL_LAYOUT_TYPES_TRACKING[layoutType],
      // This is always the new dash_ format identifier for sharing URLs
      // regardless of the dashboard types as sharing URLs are handled by roadie
      dashboardId,
      legacyDashboardId,
    });

    new SessionTracker('sharing', {
      dashboardType: GRAPHQL_LAYOUT_TYPES_TRACKING[layoutType],
      day_in_lifetime: organization.day_in_lifetime,
      // As sharing URLs are linked to the owner inception.json doesn't expose the tracking_id as its
      // private info so we generate a random UUID - because track will always lookup the user by the
      // user_id which is existing. Track expects a distinct id - so we send a random one
      distinct_id: getRandomTrackingId(),
      gb_user_id: user.id,
      master_account_name: organization.account,
      organization_id: organization.id,
      trial_days_remaining: organization.trial_days_remaining,
      trial_end: user.trial_end,
    }).start();
  };

// initialiseMobileGraphQLPage is used by mobile view in edit mode
// and also by mobile in sharing URL (if you get mobile view) which can happen
// with an iframe of mobile width or if you visit /inception on the sharing hash
export const initialiseMobileGraphQLPage =
  (sharingHash) => async (dispatch) => {
    try {
      if (sharingHash) {
        // We want and need to fetch the allowances which are returned in
        // sharing URL bootstrap.json as user endpoint 401 for sharing urls
        const data = await fetchJSONWithCredentials(
          `/v5/dashboards/${sharingHash}/bootstrap.json`,
        );
        const { currentUser: user } = data;
        dispatch(getCurrentUserSuccess(user));
      } else {
        // This only works for authenticated users logged in
        const user = await managementService.getUser();
        dispatch(getCurrentUserSuccess(user));
      }
    } catch (e) {
      dispatch(getCurrentUserFailed(e));
    }
  };

export const initialiseGraphQLEditPage = (layoutType) => async (dispatch) => {
  const user = await managementService.getUser();
  const organization = await managementService.getOrganization(
    user.organization_id,
  );

  dispatch(getUserOrganizationSuccess(organization));
  dispatch(getCurrentUserSuccess(user));

  new SessionTracker('admin', {
    dashboardType: GRAPHQL_LAYOUT_TYPES_TRACKING[layoutType],
    day_in_lifetime: organization.day_in_lifetime,
    distinct_id: user.tracking_id,
    gb_user_id: user.id,
    master_account_name: organization.account,
    organization_id: organization.id,
    trial_days_remaining: organization.trial_days_remaining,
    trial_end: user.trial_end,
  }).start();
};

export const initialiseAccountPage = () => async (dispatch) => {
  const user = await managementService.getUser();
  const organization = await managementService.getOrganization(
    user.organization_id,
  );

  dispatch(getUserOrganizationSuccess(organization));
  dispatch(getCurrentUserSuccess(user));
};
