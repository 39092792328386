import { ApolloCache, useQuery } from '@apollo/client';

import { graphql } from '../generated/gql';

export const OrgDashboardAllowanceQuery = graphql(`
  query OrgDashboardAllowance {
    currentUser {
      id
      organization {
        id
        allowances {
          dashboards {
            hardLimit
            usage
          }
        }
      }
    }
  }
`);

const useDashboardAllowance = () => {
  const { data, loading, error } = useQuery(OrgDashboardAllowanceQuery);

  if (!data || loading || error) {
    return { loading, error };
  }

  const {
    currentUser: {
      organization: {
        allowances: { dashboards },
      },
    },
  } = data;

  return {
    data: {
      usage: dashboards.usage,
      hardLimit: dashboards.hardLimit,
      hasReachedDashboardLimit: dashboards.usage >= dashboards.hardLimit,
    },
    loading: false,
  };
};

export const evictCachedAllowanceData = (cache: ApolloCache<unknown>) => {
  const data = cache.readQuery({ query: OrgDashboardAllowanceQuery });
  if (!data) {
    return;
  }
  const organization = data?.currentUser.organization;
  cache.evict({
    id: cache.identify(organization),
    fieldName: 'allowances',
  });
};

export default useDashboardAllowance;
