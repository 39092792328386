import * as universalConfigSaveActions from '../../../universal-config/actions/universal-config-save-actions';
import * as instrumentDataActions from '../actions/instrument-data-actions';

const initialState = {};

const instrumentDataReducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case instrumentDataActions.dataReceived.type:
      return {
        ...state,
        [payload.instrumentId]: {
          payload: payload.data,
          dataUpdatedAt: payload.dataUpdatedAt,
          isDataFromCache: payload.isDataFromCache,
          refresh: payload.refresh,
          dataError: null,
          isLoading: false,
        },
      };

    case instrumentDataActions.dataError.type: {
      let instrumentId = payload;
      let refresh;

      // Old payload was just the instrumentId so
      // double check its not a string before destructure
      if (typeof payload === 'object') {
        instrumentId = payload.instrumentId;
        refresh = payload.refresh;
      }

      const instrument = state[instrumentId] || {};
      return {
        ...state,
        [instrumentId]: {
          ...instrument,
          payload: null,
          dataError: error,
          refresh,
        },
      };
    }

    // Update instrument data when the config has been updated in the universal UI.
    // That way the instrument can show the fresh data, that was fetched while
    // editing the widget.
    case universalConfigSaveActions.configUpdated.type: {
      const instrument = state[payload.instrumentId] || {};

      return {
        ...state,
        [payload.instrumentId]: {
          ...instrument,
          payload: payload.data,
          dataError: undefined,
        },
      };
    }

    case instrumentDataActions.setIsLoading.type: {
      const instrument = state[payload.instrumentId] || {};

      return {
        ...state,
        [payload.instrumentId]: {
          ...instrument,
          isLoading: true,
        },
      };
    }

    default:
      return state;
  }
};

export default instrumentDataReducer;
