import bugsnag from '../../lib/bugsnag';
import { csrfFetch } from '../../universal/fetch';

const printEvent = (event) => {
  /* eslint-disable no-console */
  console.groupCollapsed(`Event: ${event.schema.title}`);
  console.log('event: ', event.event);
  console.log('schema: ', event.schema);
  console.groupEnd();
  /* eslint-enable no-console */
};

// This is where we'll save the redux store and
// apollo client once `Tracking.setReduxStore`
// and `Tracking.setApolloClient` have been called.
let store;
let apolloClient;
let trackEventOverride;

/**
 *
 * @param {[]({reduxState: object, apolloClient: object}) => object} resolvers
 * @returns object
 */
const resolveContext = async (resolvers = []) => {
  const resolverArgs = {
    reduxState: store.getState(),
    apolloClient,
  };

  // Most context resolvers are synchronous, but some may be async if they're querying apollo.
  // Rather than try to mix and match sync and async code, let's treat it all as async so that
  // it's easier to read.
  // `Promise.resolve` will wrap things that aren't a promise in a promise that resolves instantly.
  const asyncContexts = resolvers.map((resolver) =>
    Promise.resolve(resolver(resolverArgs)),
  );

  // Now we can wait for all the promises to resolve
  const allProps = await Promise.all(asyncContexts);

  // and merge all of the contexts into a single piece of context for the event
  let properties = {};
  allProps.forEach((resolvedContext) => {
    properties = { ...properties, ...resolvedContext };
  });

  return properties;
};

async function trackEvent(event, isDev = __DEV__) {
  // We can set an override in tests and storybook so that we don't have to
  // have the correct data in redux or an apollo client.
  if (trackEventOverride) {
    trackEventOverride(event);
    return;
  }

  // If we don't yet have a Redux store we won't dispatch the event
  if (!store) {
    const errorMessage = `Tracking event: "${event.schema.title}" was dispatched before the Redux store was initialised`;
    bugsnag.send(errorMessage);
    return;
  }

  // If we don't yet have the Apollo client we won't dispatch the event
  if (!apolloClient) {
    const errorMessage = `Tracking event: "${event.schema.title}" was dispatched before the Apollo client was initialised`;
    bugsnag.send(errorMessage);
    return;
  }

  try {
    const eventWithContext = {
      event: {
        ...(await resolveContext(event.contextResolvers)),
        ...event.event,
      },
      schema: event.schema,
      timestamp: Date.now(),
    };

    if (isDev) {
      printEvent(eventWithContext);
    }

    // We never want to throw an error from trackEvent()
    // because tracking should never stop the user from
    // doing what they are trying to do. If tracking fails
    // we will notify Bugsnag but still return a resolved
    // Promise.
    try {
      await csrfFetch(`${window.BACKTRACK_HOST}/event`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(eventWithContext),
      });
    } catch (e) {
      bugsnag.send(e, {
        metaData: {
          trackEvent: {
            title: event.schema.title,
            properties: eventWithContext.event,
          },
        },
      });
    }
  } catch (e) {
    bugsnag.send(e);
  }
}

function setReduxStore(reduxStore) {
  store = reduxStore;
}

function setApolloClient(client) {
  apolloClient = client;
}

function setTrackingOverride(fn) {
  trackEventOverride = fn;
}

// Useful for testing to reset the redux store and apollo client
function reset() {
  store = undefined;
  apolloClient = undefined;
}

export default trackEvent;
export { reset, setApolloClient, setReduxStore, setTrackingOverride };
